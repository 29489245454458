import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import Layout from "../components/Layout";
import Logo from "../images/logo.inline.svg";
import Seo from "../components/Seo";

const Wrapper = styled.div`
  border-top: solid 8px ${props => props.theme.colors.orange};
  border-bottom: solid 8px ${props => props.theme.colors.orange};
  min-height: 100vh;
  svg {
    width: 180px;
    max-width: 80%;
    height: auto;
  }
  h1 {
    font-family: "Inter", sans-serif;
  }
  a {
    color: inherit;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: ${props => props.theme.colors.orange};
      text-decoration: none;
    }
  }
`;

const Homepage = () => {
  return (
    <Layout>
      <Seo title="Próximamente" />
      <Wrapper className="d-flex align-items-center justify-content-center">
        <Container className="text-center">
          <Logo />
          <h1 className="mt-4">Estamos en construcción</h1>
          <p>
            Visítanos en instagram{" "}
            <a
              href="https://www.instagram.com/brantt.studio/"
              target="_blank"
              rel="noreferrer"
            >
              @brantt.studio
            </a>
          </p>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Homepage;
